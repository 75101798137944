import React from "react";

export const confirmDefault = {
  header: "Are you sure?",
  content: "Please confirm this action",
  positive: "Yes",
  negative: "Cancel"
};

export const confirmDelete = name => ({
  ...confirmDefault,
  content: (
    <>
      You are about to delete <strong>{name}</strong> from this list.
    </>
  )
});

export const confirmSendActionPlan = () => ({
  ...confirmDefault,
  content:
    "The latest report will be attached to an email that we now send you."
});

export const signInHeader = "Please sign in";
export const signInButton = "Sign in";

export const signUpHeader = "Registration";
export const signUpButton = "Sign up";

export const mandatoryFieldNote = "This field is required";

export const textFilterPlaceholder = "Search";

export const emailPlaceholder = "Enter your email address...";
export const passwordPlaceholder = "Enter your password...";
export const resetPasswordPlaceholder = "Enter your new password...";
export const resetPasswordConfirmationPlaceholder =
  "Enter your new password again...";

export const forgottenPasswordHeader = "Forgotten your password?";
export const forgottenPasswordBody =
  "Please enter your email address and we'll send you a link to reset your password.";
export const forgottenPasswordButton = "Send email";
export const forgottenYourPassword = "Forgotten your password?";

export const resetPasswordHeader = "Reset your password";
export const resetPasswordButton = "Reset password";
export const resetYourPassword = "Reset your password";

export const maintenanceHeader = "Temporary maintenance";
export const maintenanceCopy =
  "We are sorry, but this site is currently down for maintenance. Please try again later.";

export const privacyNotice = onclick => (
  <label>
    I confirm I have read the{" "}
    <span className="link" onClick={onclick}>
      Privacy Notice
    </span>
    .
  </label>
);

export const fieldNotSet = "-";
export const noNumberEntered = "No number has been entered.";
export const dateNotSet = "No date has been entered.";

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export const doesntLookRight = "The form cannot be submitted due to the following issues...";
export const siteName = process.env.REACT_APP_SITE_NAME || "Parish Dashboards";
